<template>
  <v-dialog v-model="dialog" :max-width="maxWidth" persistent>
    <template v-slot:activator="scope">
      <slot name="activator" v-bind="{ ...scope }">
        <default-button
          :id="btnId"
          :data-test="btnDataTest"
          :disabled="disabled"
          :color="btnColor"
          :on="scope.on"
          :icon="btnIcon"
          >{{ btnText }}</default-button
        >
      </slot>
    </template>
    <v-card flat tile>
      <v-card-title class="mb-3" id="dialog-title">
        <span>
          {{ title }}
        </span>
        <v-spacer />
        <v-btn :id="btnId" icon @click="dialog = false"
          ><v-icon>fa-times</v-icon></v-btn
        >
      </v-card-title>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
import DefaultButton from "@/components/widgets/DefaultButton";
export default {
  components: { DefaultButton },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    btnText: {
      type: String,
    },
    btnId: {
      type: String,
    },
    btnDataTest: {
      type: String,
    },
    maxWidth: {
      type: Number,
      default: 800,
    },
    btnIcon: {
      type: String,
    },
    btnColor: {
      type: String,
      default: "info",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
