const mutationNames = [
  "createAccount",
  "tokenAuth",
  "createRegistry",
  "deleteTag",
  "deleteVersion",
  "editGroup",
  "createServiceAccount",
  "deleteServiceAccount",
  "rotateKeys",
  "deleteNpmVersion",
  "deleteRegistry",
  "createGroup",
  "deleteGroup",
  "createUser",
  "setPassword",
  "deleteUser",
  "editPackagePermissions",
  "editNpmPackagePermissions",
  "editRegistryPermissions",
  "editAccountConfig",
  "requestPasswordReset",
  "setPaymentMethod",
  "updateSubscription",
];

const queryNames = [
  "user",
  "packages",
  "account",
  "dashboard",
  "registries",
  "registry",
  "pythonPackage",
  "groups",
  "newAccount",
  "permissions",
  "users",
  "serviceAccounts",
  "npmPackages",
  "npmPackage",
  "userByResetToken",
  "accessLogs",
  "accountConfiguration",
  "userPermissions",
];

export const mutations = mutationNames.reduce(function (map, name) {
  map[name] = require(`@/mutations/${name}.gql`);
  return map;
}, {});

export const queries = queryNames.reduce(function (map, name) {
  map[name] = require(`@/queries/${name}.gql`);
  return map;
}, {});

export default {
  queries,
  mutations,
};
