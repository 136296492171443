<template>
  <auth-wrapper>
    <v-snackbar v-model="error" color="error">{{ errorMessage }}</v-snackbar>
    <v-card-title class="justify-center">
      <div>
        <h1 class="text-center mb-2 display-4 font-weight-thin">hello</h1>
        <p class="body-2 text-center grey--text small">Login to your account</p>
      </div>
    </v-card-title>
    <v-container>
      <v-form v-model="valid">
        <v-text-field
          label="Email"
          type="email"
          id="email"
          solo
          :rules="required"
          v-model="form.username"
        ></v-text-field>
        <v-text-field
          label="Password"
          type="password"
          id="password"
          solo
          v-model="form.password"
          :rules="required"
        ></v-text-field>
      </v-form>
    </v-container>
    <v-card-actions>
      <v-btn
        class="nice-button primary"
        x-large
        id="submit"
        rounded
        block
        :disabled="!valid"
        :loading="loading"
        @click="login"
        >Login</v-btn
      >
    </v-card-actions>
    <v-card-actions>
      <forgot-password-dialog />
      <v-spacer />
      <v-btn text class="nice-button grey--text text--darken-2" to="/register"
        >Don't yet have an account?</v-btn
      >
    </v-card-actions>
  </auth-wrapper>
</template>

<script>
import AuthWrapper from "@/components/AuthWrapper";
import { mutations } from "@/gql";
import Cookie from "js-cookie";
import { BACKEND_DOMAIN } from "@/config";
import ForgotPasswordDialog from "./dialogs/ForgotPasswordDialog";
const { tokenAuth: mutation } = mutations;

export default {
  components: {
    ForgotPasswordDialog,
    AuthWrapper,
  },
  data: () => ({
    valid: null,
    error: false,
    errorMessage: null,
    form: {
      username: null,
      password: null,
    },
    required: [(v) => !!v || "This field is required"],
    loading: false,
  }),
  methods: {
    login() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation,
          variables: this.form,
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.message;
          this.loading = false;
        })
        .then(
          ({
            data: {
              tokenAuth: { token },
            },
          }) => {
            Cookie.set("JWT", token, { domain: BACKEND_DOMAIN });
            this.$gtag.event("login", {
              event_category: process.env.VUE_APP_BACKEND_DOMAIN,
              event_label: `username: ${this.form.username}`,
              value: 24,
            });
            window.location.href = "/";
            this.loading = false;
          },
        );
    },
  },
};
</script>

<style scoped>
.nice-button {
  text-transform: none !important;
  letter-spacing: 0;
}
</style>
