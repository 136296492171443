<template>
  <div>
    <v-snackbar v-model="error" color="error">{{ errorMessage }}</v-snackbar>
    <v-snackbar v-model="success" color="success"
      >Password reset requested - please check your email</v-snackbar
    >

    <Dialog
      title="Password reset"
      v-model="dialog"
      btn-text="Forgot password?"
      btn-color="grey darken-3"
    >
      <v-card-text>
        Please enter your email address below and will send you a password reset
        link
      </v-card-text>
      <v-card-text>
        <v-form v-model="valid">
          <v-text-field
            label="Email"
            solo
            v-model="username"
            :rules="[rules.required, rules.email]"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <default-button
          :loading="loading"
          :disabled="!valid"
          @click="resetPassword"
        >
          Send password reset link
        </default-button>
      </v-card-actions>
    </Dialog>
  </div>
</template>
<script>
import Dialog from "./Dialog";
import DefaultButton from "../widgets/DefaultButton";
import { mutations } from "../../gql";

const { requestPasswordReset: mutation } = mutations;

export default {
  components: { DefaultButton, Dialog },
  data: () => ({
    dialog: false,
    rules: {
      required: (v) => !!v || "This field is required",
      email: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    },
    valid: null,
    loading: false,
    username: null,
    error: false,
    errorMessage: null,
    success: false,
  }),
  methods: {
    resetPassword() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation,
          variables: { username: this.username },
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error.message.replace("GraphQL error: ", "");
          this.loading = false;
        })
        .then((result) => {
          if (result) {
            this.success = true;
            this.dialog = false;
          }
          this.loading = false;
        });
    },
  },
};
</script>
