import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "Home",
    component: Dashboard,
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () => import("../views/Home.vue"),
      },
      {
        path: "/python",
        name: "Python Packages",
        component: () => import("../views/PythonPackages.vue"),
      },
      {
        path: "/python/:id",
        name: "Python Package View",
        component: () => import("../views/PythonPackage.vue"),
      },
      {
        path: "/docker",
        name: "Docker Registries",
        component: () => import("../views/Docker.vue"),
      },
      {
        path: "/docker/:id",
        name: "Docker Registry View",
        component: () => import("../views/DockerRegistry.vue"),
      },
      {
        path: "/npm",
        name: "NPM Packages",
        component: () => import("../views/NpmPackages.vue"),
      },
      {
        path: "/npm/:id",
        name: "NPM Package",
        component: () => import("../views/NpmPackage.vue"),
      },
      {
        path: "/service-accounts",
        name: "Access Tokens",
        component: () => import("../views/ServiceAccounts.vue"),
      },
      {
        path: "/roles",
        name: "Roles",
        component: () => import("../views/Roles.vue"),
      },
      {
        path: "/logs",
        name: "Access Logs",
        component: () => import("../views/AccesLogs.vue"),
      },
      {
        path: "/configuration",
        name: "Account Preferences",
        component: () => import("../views/AccountConfiguration.vue"),
      },
      {
        path: "/billing",
        name: "Billing Dashboard",
        component: () => import("../views/Billing.vue"),
      },
      {
        path: "/users",
        name: "Users",
        component: () => import("../views/Users.vue"),
      },
    ],
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Registration.vue"),
  },
  {
    path: "/verify",
    name: "Verify",
    component: () => import("../views/Verify.vue"),
  },
  {
    path: "/set-password/:token",
    name: "Set password",
    component: () => import("../views/SetPassword.vue"),
  },
];

const router = new VueRouter({
  routes,
});

Sentry.init({
  Vue,
  dsn: "https://1be3c484d47549589be980807298873f@o1067127.ingest.sentry.io/6060508",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "app.packagr.app", "app.stage.packagr.app"],
    }),
  ],
  tracesSampleRate: 1.0,
});

export default router;
