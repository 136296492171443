<template>
  <v-app v-if="user">
    <v-main>
      <v-app-bar app clipped-left flat dark>
        <v-toolbar-title class="font-weight-light white--text">
          Packagr
        </v-toolbar-title>
        <v-spacer />
        <v-menu bottom left>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">
              {{ user.username }}
              <v-icon right>fa-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="logout">
              <v-list-item-icon
                ><v-icon right>fa-sign-out-alt</v-icon></v-list-item-icon
              >
              <v-list-item-title>Log out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
      <nav-drawer />
      <v-container
        style="height: 100%"
        class="py-2 blue-grey lighten-5 top"
        align="top"
      >
        <router-view />
      </v-container>
    </v-main>
  </v-app>
  <login v-else-if="!$apollo.queries.user.loading" />
  <Loader v-else :value="true" />
</template>

<script>
import Cookie from "js-cookie";
import Login from "@/components/Login";
import NavDrawer from "@/components/NavDrawer";
import Loader from "@/components/base/Loader";
import { queries } from "@/gql";
import { BACKEND_DOMAIN } from "@/config";

const { user } = queries;

export default {
  components: { Loader, Login, NavDrawer },
  apollo: {
    user,
  },
  methods: {
    logout() {
      Cookie.remove("JWT", { domain: BACKEND_DOMAIN });
      window.location.reload();
    },
  },
};
</script>
