var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"app":"","clipped":""}},[_c('v-list',{staticClass:"mt-3",attrs:{"subheader":"","dense":"","nav":""}},[(
        _vm.hasPermission(_vm.packageReadPermissions.concat( [_vm.roles.manageLogs]), true)
      )?_c('v-list-item',{attrs:{"to":"/"}},[_c('v-list-item-icon',[_c('i',{staticClass:"fas fa-home mt-1 ml-3"})]),_c('v-list-item-title',[_vm._v(" Dashboard ")])],1):_vm._e(),_c('div',{attrs:{"id":"dashboard-step4"}},[(_vm.hasPermission(_vm.packageReadPermissions))?_c('v-subheader',[_vm._v("Package repositories")]):_vm._e(),(_vm.hasPermission([_vm.roles.pullPythonPackage]))?_c('v-list-item',{attrs:{"to":"/python","id":"dashboard-step5"}},[_c('v-list-item-icon',[_c('i',{staticClass:"fab fa-python mt-1 ml-3"})]),_c('v-list-item-title',[_vm._v(" Python Packages ")])],1):_vm._e(),(_vm.hasPermission([_vm.roles.pullDockerRegistry]))?_c('v-list-item',{attrs:{"to":"/docker"}},[_c('v-list-item-icon',[_c('i',{staticClass:"fab fa-docker mt-1 ml-3"})]),_c('v-list-item-title',[_vm._v(" Docker Registries ")])],1):_vm._e(),(_vm.hasPermission([_vm.roles.pullNpmPackage]))?_c('v-list-item',{attrs:{"to":"/npm"}},[_c('v-list-item-icon',[_c('i',{staticClass:"fab fa-npm mt-1 ml-3"})]),_c('v-list-item-title',[_vm._v(" NPM packages ")])],1):_vm._e()],1),(
        _vm.hasPermission([
          _vm.roles.manageUsers,
          _vm.roles.manageGroups,
          _vm.roles.manageTokens ])
      )?_c('v-subheader',[_vm._v("Access controls")]):_vm._e(),(_vm.hasPermission([_vm.roles.manageUsers]))?_c('v-list-item',{attrs:{"to":"/users"}},[_c('v-list-item-icon',[_c('i',{staticClass:"fas fa-user-circle mt-1 ml-3"})]),_c('v-list-item-title',[_vm._v(" Users ")])],1):_vm._e(),(_vm.hasPermission([_vm.roles.manageRoles, _vm.roles.manageGroups]))?_c('v-list-item',{attrs:{"to":"/roles"}},[_c('v-list-item-icon',[_c('i',{staticClass:"fas fa-user-tag mt-1 ml-3"})]),_c('v-list-item-title',[_vm._v(" Roles ")])],1):_vm._e(),(_vm.hasPermission([_vm.roles.manageTokens]))?_c('v-list-item',{attrs:{"to":"/service-accounts"}},[_c('v-list-item-icon',[_c('i',{staticClass:"fas fa-key mt-1 ml-3"})]),_c('v-list-item-title',[_vm._v(" Access tokens ")])],1):_vm._e(),_c('v-subheader',[_vm._v("Misc")]),(_vm.hasPermission([_vm.roles.manageLogs]))?_c('v-list-item',{attrs:{"to":"/logs"}},[_c('v-list-item-icon',[_c('i',{staticClass:"fas fa-clipboard-list mt-1 ml-3"})]),_c('v-list-item-title',[_vm._v(" Access logs ")])],1):_vm._e(),_c('v-list-item',{attrs:{"href":_vm.helpUrl,"target":"_blank"}},[_c('v-list-item-icon',[_c('i',{staticClass:"fas fa-book mt-1 ml-3"})]),_c('v-list-item-title',[_vm._v(" Documentation ")]),_c('v-list-item-icon',[_c('i',{staticClass:"fas fa-external-link-alt grey--text mt-1 ml-3"})])],1),(_vm.hasPermission([_vm.roles.manageBilling, _vm.roles.manageSettings]))?_c('v-subheader',[_vm._v("Account")]):_vm._e(),(_vm.hasPermission([_vm.roles.manageSettings]))?_c('v-list-item',{attrs:{"to":"/configuration"}},[_c('v-list-item-icon',[_c('i',{staticClass:"fas fa-cogs mt-1 ml-3"})]),_c('v-list-item-title',[_vm._v(" Account configuration ")])],1):_vm._e(),(_vm.hasPermission([_vm.roles.manageBilling]))?_c('v-list-item',{attrs:{"to":"/billing"}},[_c('v-list-item-icon',[_c('i',{staticClass:"far fa-credit-card mt-1 ml-3"})]),_c('v-list-item-title',[_vm._v(" Billing ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }