import Vue from "vue";
import VueApollo from "vue-apollo";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { GQL_HOST as uri } from "@/config";

Vue.use(VueApollo);

const httpLink = createHttpLink({
  uri,
  credentials: "include",
  opts: {
    credentials: "include",
  },
});

const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  tokenName: "JWT",
  fetchOptions: {
    credentials: "include",
  },
  cache,
  credentials: "include",
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

export default apolloProvider;
