<template>
  <v-navigation-drawer app clipped>
    <v-list subheader dense nav class="mt-3">
      <v-list-item
        to="/"
        v-if="
          hasPermission([...packageReadPermissions, roles.manageLogs], true)
        "
      >
        <v-list-item-icon>
          <i class="fas fa-home mt-1 ml-3" />
        </v-list-item-icon>
        <v-list-item-title> Dashboard </v-list-item-title>
      </v-list-item>
      <div id="dashboard-step4">
        <v-subheader v-if="hasPermission(packageReadPermissions)"
          >Package repositories</v-subheader
        >
        <v-list-item
          to="/python"
          v-if="hasPermission([roles.pullPythonPackage])"
          id="dashboard-step5"
        >
          <v-list-item-icon>
            <i class="fab fa-python mt-1 ml-3" />
          </v-list-item-icon>
          <v-list-item-title> Python Packages </v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/docker"
          v-if="hasPermission([roles.pullDockerRegistry])"
        >
          <v-list-item-icon>
            <i class="fab fa-docker mt-1 ml-3" />
          </v-list-item-icon>
          <v-list-item-title> Docker Registries </v-list-item-title>
        </v-list-item>
        <v-list-item to="/npm" v-if="hasPermission([roles.pullNpmPackage])">
          <v-list-item-icon>
            <i class="fab fa-npm mt-1 ml-3" />
          </v-list-item-icon>
          <v-list-item-title> NPM packages </v-list-item-title>
        </v-list-item>
      </div>
      <v-subheader
        v-if="
          hasPermission([
            roles.manageUsers,
            roles.manageGroups,
            roles.manageTokens,
          ])
        "
        >Access controls</v-subheader
      >
      <v-list-item to="/users" v-if="hasPermission([roles.manageUsers])">
        <v-list-item-icon>
          <i class="fas fa-user-circle mt-1 ml-3" />
        </v-list-item-icon>
        <v-list-item-title> Users </v-list-item-title>
      </v-list-item>
      <v-list-item
        to="/roles"
        v-if="hasPermission([roles.manageRoles, roles.manageGroups])"
      >
        <v-list-item-icon>
          <i class="fas fa-user-tag mt-1 ml-3" />
        </v-list-item-icon>
        <v-list-item-title> Roles </v-list-item-title>
      </v-list-item>
      <v-list-item
        to="/service-accounts"
        v-if="hasPermission([roles.manageTokens])"
      >
        <v-list-item-icon>
          <i class="fas fa-key mt-1 ml-3" />
        </v-list-item-icon>
        <v-list-item-title> Access tokens </v-list-item-title>
      </v-list-item>
      <v-subheader>Misc</v-subheader>
      <v-list-item to="/logs" v-if="hasPermission([roles.manageLogs])">
        <v-list-item-icon>
          <i class="fas fa-clipboard-list mt-1 ml-3" />
        </v-list-item-icon>
        <v-list-item-title> Access logs </v-list-item-title>
      </v-list-item>
      <v-list-item :href="helpUrl" target="_blank">
        <v-list-item-icon>
          <i class="fas fa-book mt-1 ml-3" />
        </v-list-item-icon>
        <v-list-item-title> Documentation </v-list-item-title>
        <v-list-item-icon
          ><i class="fas fa-external-link-alt grey--text mt-1 ml-3"
        /></v-list-item-icon>
      </v-list-item>
      <v-subheader
        v-if="hasPermission([roles.manageBilling, roles.manageSettings])"
        >Account</v-subheader
      >
      <v-list-item
        to="/configuration"
        v-if="hasPermission([roles.manageSettings])"
      >
        <v-list-item-icon>
          <i class="fas fa-cogs mt-1 ml-3" />
        </v-list-item-icon>
        <v-list-item-title> Account configuration </v-list-item-title>
      </v-list-item>
      <v-list-item to="/billing" v-if="hasPermission([roles.manageBilling])">
        <v-list-item-icon>
          <i class="far fa-credit-card mt-1 ml-3" />
        </v-list-item-icon>
        <v-list-item-title> Billing </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { queries } from "@/gql";
const { userPermissions } = queries;

const roles = {
  manageRoles: "Manage Roles",
  pullPythonPackage: "Pull Python package",
  pullDockerRegistry: "Pull Docker registry",
  pullNpmPackage: "Pull NPM package",
  manageUsers: "Manage Users",
  manageGroups: "Manage Groups",
  manageTokens: "Manage Access Token",
  manageLogs: "Manage Access Log",
  manageSettings: "Manage Settings",
  manageBilling: "Manage Billing",
};

const packageReadPermissions = [
  roles.pullPythonPackage,
  roles.pullDockerRegistry,
  roles.pullNpmPackage,
];

const helpUrl = process.env.VUE_APP_DOCS_URL;
export default {
  data: () => ({
    helpUrl,
    roles,
    packageReadPermissions,
  }),
  apollo: { userPermissions },
  computed: {
    cleanedPermissions() {
      if (this.userPermissions) {
        return this.userPermissions.map((perm) => perm.name);
      }
      return [];
    },
  },
  methods: {
    hasPermission(permissions, requireAll) {
      for (var p in permissions) {
        const perm = permissions[p];
        if (this.cleanedPermissions.indexOf(perm) > -1) {
          if (!requireAll) return true;
        } else {
          if (requireAll) return false;
        }
      }
      return true;
    },
  },
};
</script>
