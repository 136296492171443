<template>
  <v-btn
    :disabled="disabled"
    class="px-0 nice-button mx-2"
    text
    :href="href"
    :loading="loading"
    :color="color"
    :to="to"
    @click="$emit('click')"
    v-on="on"
  >
    <v-icon v-if="icon" class="pr-2" small>{{ icon }}</v-icon>
    <slot />
  </v-btn>
</template>

<script>
export default {
  props: {
    icon: { type: String },
    on: { type: Object },
    to: { type: String },
    href: { type: String },
    color: { type: String, default: "info" },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
.nice-button {
  text-transform: none !important;
  letter-spacing: 0;
}
</style>
